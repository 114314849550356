@import url(https://fonts.googleapis.com/css?family=Overpass+Mono&display=swap);
body {
    margin: 0;
    height: 100%;
    overflow: hidden;
    background: linear-gradient(-45deg, #321a47, #743eac, #533ba3, #97bbe8, #b058c6);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.text {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;

    color: black;
    font-family: 'Overpass Mono', monospace;
    font-weight: normal;
}

.header-image {
    height: 10%;
    width: 10%;
}

.link-text {
    text-decoration: none;
    color: black;
}

.weather-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

}.location{
    font-family: 'Overpass Mono', monospace;
    font-size: .9em;
    font-weight: normal;

    text-decoration: none;

    margin: 4px;
}.forecast{
    max-height: 2.7em;

    font-family: 'Overpass Mono', monospace;
    font-size: .9em;
    font-weight: normal;

    text-decoration: none;

    margin: 4px;


    position: relative;
    overflow: hidden;
    /* Saving this here for when I want the data to scroll :p */
    /* -webkit-animation: mymove 2s ease 0s; */
    /* animation: mymove 20s infinite; */
}
/* @keyframes mymove {
    from {top: 0px;}
    to {top: -325px;}
    } */
/* @-webkit-keyframes mymove {    from {top: 0px;}
to {top: -325px;}} */

.weather-header-image {
    height: 2.7em;
    width: 2.7em;
}
